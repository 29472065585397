import axios from 'axios';
import userSettings from '@/userSettings';
import Localized from '@/Localized';

let user = null;
const defaultHeaders = {'X-Requested-With': 'personights-admin-panel'};
const apiUrl = process.env.VUE_APP_API_URL;

export default new class {

    constructor() {
        this.events = {};
        this.suppressAllErrors = false;
        this.suppressErrors = {};
    }

    async login(email, password, totp) {
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.post(`${apiUrl}authorize`, {
                email,
                password,
                totp,
            }, {
                headers: defaultHeaders,
            });

            if(response.data.login === true) {
                user = response.data.user;
                
                this.emit('user-change', user);
            } else {
                user = null;
            }
            
            // console.log(response.data)
            return response.data;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return {login: false};
        }
    }

    async isLoggedIn() {
        if(user !== null) return true;

        try {
            const response = await axios.get(`${apiUrl}user/self`, {
                headers: defaultHeaders,
            });

            if(response.data) {
                user = response.data;
            }
        } catch(error) {
            user = null;
        }

        return user !== null;
    }

    async hasTOTP() {
        const loggedIn = await this.isLoggedIn();

        return loggedIn && user.has_totp;
    }

    on(event, callback) {
        if(typeof this.events[event] === 'undefined') {
            this.events[event] = [];
        }

        this.events[event] = [
            ...this.events[event],
            callback
        ];
    }

    emit(event) {
        if(typeof this.events[event] === 'undefined') return;

        const args = [...arguments].slice(1);

        this.events[event].forEach(function(callback) {
            callback.apply(callback, args);
        });
    }

    processFailedRequest(response, suppressorId = null) {
        if(response.status === 401) return this.logout();

        if(this.suppressAllErrors || suppressorId !== null) return;

        switch(response.status) {
            case 404:
                return this.emit('notification', 'danger', response.data);
            case 422:
                return this.emit('input-error', response.data.errors);
            case 500:
                return this.emit('notification', 'danger', 'Bei der API ist ein Fehler aufgetreten!');
        }
    }

    logout() {
        user = null;

        this.emit('logout');
    }

    getUser() {
        console.log(user);

        return {
            id: user.id,
            name: user.name,
            email: user.email,
            login_available: user.login_available,
            email_unanswered_bookings: user.email_unanswered_bookings,
        };
    }

    async updateProfile(userData) {
        const formData = {
            name: userData.name,
            email: userData.email,
            email_unanswered_bookings: userData.email_unanswered_bookings,
        };

        if(userData.password) {
            formData.password = userData.password;
        }

        const suppressorId = this.getErrorSuppressorId();
        
        try {
            const response = await axios.put(`${apiUrl}user/${user.id}`, formData, {
                headers: defaultHeaders,
            });

            if(response.data) {
                user = response.data;

                this.emit('user-change', user);
            }

            return true;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return false;
        }
    }

    async updateTOTP() {
        const suppressorId = this.getErrorSuppressorId();
        
        try {
            const response = await axios.get(`${apiUrl}totp/${user.id}`, {
                headers: defaultHeaders,
            });

            if(typeof response.data !== 'undefined' && typeof response.data.qr_code !== 'undefined') {
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async resetTOTP(userId) {
        const suppressorId = this.getErrorSuppressorId();
        
        try {
            const response = await axios.delete(`${apiUrl}totp/${userId}`, {
                headers: defaultHeaders,
            });

            if(typeof response.data !== 'undefined') {
                return true;
            }

            return false;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return false;
        }
    }

    async getLatLong(address, postalcode, country) {
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.get(`${apiUrl}latlong`, {
                params: {
                    address,
                    postalcode,
                    country,
                },
                headers: defaultHeaders,
            });

            if(response.data) {
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async loadTableData(table, searchData = {}, page = 1, sorting = null, lang = null) {
        const params = {
            ...this.removeEmptyValuesFromObject(searchData),
            lang: lang || userSettings.getSetting('defaultEditLanguage'),
        };

        if(page !== 0) {
            params.paginate_count = userSettings.getSetting('rowsPerTable');
            params.paginate_page = page;
        }

        if(sorting !== null) {
            params.sort_field = sorting.field;
            params.sort_dir = sorting.dir;
        }
        
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.get(`${apiUrl}${table}`, {
                params,
                headers: defaultHeaders,
            });

            if(typeof response.data !== 'undefined' && Array.isArray(response.data.data)) {
                response.data.data = response.data.data.map(entry => new Localized(entry));

                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    removeEmptyValuesFromObject(object) {
        const result = {};

        for(let [key, value] of Object.entries(object)) {
            if(value === '' || value === null) continue;

            result[key] = value;
        }

        return result;
    }

    async loadEntry(table, id, lang = null) {
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.get(`${apiUrl}${table}/${id}`, {
                params: {
                    lang: lang || userSettings.getSetting('defaultEditLanguage'),
                },
                headers: defaultHeaders,
            });

            if(response.data) {
                return new Localized(response.data);
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async createEntry(table, tableData, lang = null) {
        const languageParam = lang || userSettings.getSetting('defaultEditLanguage');
        const suppressorId = this.getErrorSuppressorId();


        console.log(tableData)
        try {
            const response = await axios.post(`${apiUrl}${table}?lang=${languageParam}`, this.jsonStringifyObjectParameters(tableData), {
                headers: defaultHeaders,
            });

            if(response.data) {
                return new Localized(response.data);
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    jsonStringifyObjectParameters(tableData) {
        for(let [key, value] of Object.entries(tableData)) {
            if(typeof value !== 'object' || value === null) continue;

            tableData[key] = JSON.stringify(value);
        }

        return tableData;
    }

    async updateEntry(table, id, tableData, lang = null) {
        const languageParam = lang || userSettings.getSetting('defaultEditLanguage');
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.put(`${apiUrl}${table}/${id}?lang=${languageParam}`, this.jsonStringifyObjectParameters(tableData), {
                headers: defaultHeaders,
            });

            if(response.data) {
                return new Localized(response.data);
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async updateEntryAndFinishStaging(table, id, tableData, lang = null) {
        const languageParam = lang || userSettings.getSetting('defaultEditLanguage');
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.put(`${apiUrl}${table}/${id}?lang=${languageParam}`, this.jsonStringifyObjectParameters(tableData), {
                headers: defaultHeaders,
            });

            if(response.data) {
                return new Localized(response.data);
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async deleteEntry(table, id) {
        const suppressorId = this.getErrorSuppressorId();

        try {
            await axios.delete(`${apiUrl}${table}/${id}`, {
                headers: defaultHeaders,
            });

            return true;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async uploadPicture(picture, tableData) {
        const formData = new FormData();

        for(let [key, value] of Object.entries(tableData)) {
            formData.append(key, value);
        }

        formData.append('picture', picture);
        
        const suppressorId = this.getErrorSuppressorId();
        
        try {
            const response = await axios.post(`${apiUrl}picture`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...defaultHeaders,
                }
            });

            if(response.data) {
                return new Localized(response.data);
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async exportCsv(table, exportFields = [], searchData = {}, lang = null) {
        const urlSearchParams = new URLSearchParams();

        for(let exportField of exportFields) {
            urlSearchParams.append('export[]', exportField);
        }

        for(let [key, value] of Object.entries(searchData)) {
            urlSearchParams.append(`search[${key}]`, value);
        }

        urlSearchParams.append('lang', lang || userSettings.getSetting('defaultEditLanguage'));
        
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.get(`${apiUrl}${table}/csv`, {
                params: urlSearchParams,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...defaultHeaders,
                }
            });

            const blob = new Blob([response.data], {type : 'text/csv'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            
            link.style.display = 'none';
            link.href = url;
            link.download = `export_${table}_${this.getTimestampForFile()}.csv`;
            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(url);

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    getTimestampForFile() {
        const date = new Date();
        const pad = number => {
            if(number < 10) return `0${number}`;
            return number;
        };

        return `${date.getFullYear()}_${pad(date.getMonth()+1)}_${pad(date.getDate())}_${pad(date.getHours())}${pad(date.getMinutes())}${pad(date.getSeconds())}`;
    }

    async getStatistics() {
        const suppressorId = this.getErrorSuppressorId();
        try {
            const response = await axios.get(`${apiUrl}statistic`, {
                headers: defaultHeaders,
            });

            if(response.data) {
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async getStatisticsForAll(table, searchData = {}, page = 1, sorting = null, lang = null) {
        const params = {
            ...this.removeEmptyValuesFromObject(searchData),
            lang: lang || userSettings.getSetting('defaultEditLanguage'),
        };

        if(page !== 0) {
            params.paginate_count = userSettings.getSetting('rowsPerTable');
            params.paginate_page = page;
        }

        if(sorting !== null) {
            params.sort_field = sorting.field;
            params.sort_dir = sorting.dir;
        }
        
        const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.get(`${apiUrl}statistic/${table}`, {
                params,
                headers: defaultHeaders,
            });

            if(typeof response.data !== 'undefined' && Array.isArray(response.data.data)) {
                response.data.data = response.data.data.map(statistic => {
                    statistic.entry = new Localized(statistic.entry);

                    return statistic;
                });

                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async getStatisticsForOne(table, id, timespan = null) {
        const suppressorId = this.getErrorSuppressorId();
        try {
            const response = await axios.get(`${apiUrl}statistic/${table}/${id}`, {
                params: this.removeEmptyValuesFromObject({
                    timespan,
                }),
                headers: defaultHeaders,
            });

            if(response.data) {
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    suppressErrorMessages() {
        this.suppressAllErrors = true;
    }

    suppressErrorMessagesForNextRequest() {
        const id = 'id'+performance.now();

        this.suppressErrors[id] = {
            assigned: false,
        };
    }

    getErrorSuppressorId() {
        for(let [id, suppressor] of Object.entries(this.suppressErrors)) {
            if(typeof suppressor !== 'undefined' && suppressor.assigned === false) {
                this.suppressErrors[id].assigned = true;

                return id;
            }
        }

        return null;
    }

    async getEmailTemplates() {
        const suppressorId = this.getErrorSuppressorId();
        try {
            const response = await axios.get(`${apiUrl}email_templates`, {
                headers: defaultHeaders,
            });

            if(response.data) {
                // console.log("response.data von api", response.data)
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async setEmailTemplates(content, id) {
        const suppressorId = this.getErrorSuppressorId();
        const body = {
            "name": content.name,
            "to": content.to,
            "from": content.from,
            "cc": content.cc,
            "template": content.template,
        }

        try {
            const response = await axios.put(`${apiUrl}email_templates/${id}`, body,  {
                headers: defaultHeaders,
            });

            if(response.data) {
                console.log(response.data)
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async deleteEmailTemplate(name) {
        // const suppressorId = this.getErrorSuppressorId();

        try {
            const response = await axios.put(`${apiUrl}email_templates/delete/${name}`,  {
                headers: defaultHeaders,
            });

            if(response.status) {
                return response.status;
            }

            return response;
        } catch(error) {
            // this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async getStagingHotel(seleneId) {
        const suppressorId = this.getErrorSuppressorId();
        // console.log(`${apiUrl}hotelstaging?selene_id=${seleneId}`)
        try {
            const response = await axios.get(`${apiUrl}hotelstaging?selene_id=${seleneId}`, {
                headers: defaultHeaders,
            });

            if(response.data) {
                // console.log("response.data von api",response.data)
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }

    async deleteStagingHotel(id) {
        const suppressorId = this.getErrorSuppressorId();
        try {
            const response = await axios.delete(`${apiUrl}hotelstaging/${id}`, {
                headers: defaultHeaders,
            });

            if(response.data) {
                // console.log("response.data von api: ",response.data)
                return response.data;
            }

            return null;
        } catch(error) {
            this.processFailedRequest(error.response, suppressorId);

            return null;
        }
    }
}